// en
@font-face {
  font-family: Inter;
  src: url("./fonts/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url("./fonts/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url("./fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Bitter;
  src: url("./fonts/Bitter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

// cn
@font-face {
  font-family: "Noto Sans SC";
  src: url("./fonts/NotoSansSC-Bold.otf") format("opentype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SC";
  src: url("./fonts/NotoSansSC-Regular.otf") format("opentype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./fonts/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./fonts/NotoSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}
