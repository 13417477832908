// color
$black: #000;
$white: #fff;
$gray: #909090;
$sapphire: #47a1ad;
$wave-blue: #003d4f;
$background-blue: #f7fafc;
$wave-flamingo: #bd4257;
$mist-gray: #edf1f3;
$amethyst-purple: #634f7d;
$light-gray: #666;
$light-flamingo: #f2617a;

// screen width
$large-devices: 992px;
$medium-devices: 700px;
