@import "/node_modules/antd/lib/message/style/index.css";
@import "./font";
@import "src/styles/variable";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* stylelint-disable-next-line max-line-length */
  font-family: Inter, -apple-system, "Segoe UI", "Helvetica Neue", "Noto Sans SC", "PingFang SC", "Microsoft Yahei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* stylelint-disable-next-line */
#__qiankun_microapp_wrapper_for_easy_sign__{
  height: 100%;
}

/* stylelint-disable-next-line */
#__qiankun_microapp_wrapper_for_bill_split__{
  height: 100%;
}

.ant-message {
  .ant-message-info .anticon {
    color: $sapphire;
  }
}
