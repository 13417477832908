@import "src/styles/variable";
@import "../../font";

.main-app-footer {
  background: $white;
  width: 100%;
  border-top: 0.4px solid #666;
  height: 6%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-app-footer-introduce-wrapper {
  width: 75%;
  height: 29px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 29px;
  color: $black;
  display: flex;
  justify-content: space-between;

  a {
    color: $black;
    padding: 8px;
    text-decoration: underline;

    &:hover {
      color: $wave-flamingo;
      text-decoration: none;
    }
  }

  img {
    width: 101px;
    height: 16.41px;
    margin-top: 6.3px;
    margin-left: 8px;
  }
}
