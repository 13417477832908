@import "src/styles/variable";
@import "src/font";

.main-app-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 33px;
}

.main-app-banner {
  height: 21%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.main-app-introduce {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    width: 75%;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    line-height: 25.2px;
    display: flex;
    align-items: center;
    color: #000;

    &:first-child {
      margin: 1rem 0 0;
    }

    &:last-child {
      margin: 1rem 0;
    }
  }
}

.sub-app-navigation-bar {
  background: #edf1f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  flex: 1;
}

.sub-app-navigation-bar-title {
  width: 75%;
  padding-top: 30px;
  padding-bottom: 30px;

  h1 {
    width: 100px;
    height: 29px;
    left: 150px;
    top: 409px;
    font-family: Bitter, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
    color: $black;
  }
}
