@import "src/styles/variable";
@import "../../font";

.sub-app-navigation-bar-container {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  align-content: flex-start;
  width: 75%;
}

.sub-app-navigation-bar-container-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 250px;
}

.sub-app-navigation-image {
  background-repeat: no-repeat;
  background-size: cover;
  height: 80%;
  width: 82%;
}

.sub-app-navigation-bar-entry {
  display: flex;
  flex-direction: row;
  margin: 25px 0;
  align-items: center;
  text-align: center;

  i,
  img {
    cursor: pointer;
  }
}

.sub-app-navigation-bar-logo {
  width: 20%;
  margin-right: 5%;
}

.timecardreminder {
  .sub-app-navigation-bar-logo {
    width: 40%;
  }
}

.ri-arrow-right-s-line::before {
  color: $light-flamingo;
  width: 9px;
  height: 16.41px;
  font-size: 24px;
}

@media (min-width: $medium-devices) {
  .sub-app-navigation-bar-container-item {
    width: 50%;
  }
}

@media (min-width: $large-devices) {
  .sub-app-navigation-bar-container-item {
    width: 33.3333%;
  }
}
