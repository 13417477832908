@import "src/styles/variable";

.anticon.help-dropdown-icon {
  margin-right: 20px;
  font-size: 22px;
  color: $light-gray;

  &:hover {
    color: $light-flamingo;
  }
}

.ant-tooltip-inner {
  background-color: $white;
  color: $amethyst-purple;
  border-radius: 4px;
}

.ant-tooltip-arrow-content::before {
  background: $white;
}

.ant-dropdown.help-dropdown-root {
  min-width: 230px !important;
  top: 85px !important;
  left: unset !important;
  right: 9px;
}

.help-dropdown-root {
  .ant-dropdown-menu {
    border-radius: 8px;
    padding: 12.5px 0;

    .ant-dropdown-menu-item {
      padding: 7.5px 32px;

      &:hover {
        background-color: $background-blue;
      }
    }
  }
}

.ant-dropdown-open.help-dropdown-icon {
  color: $light-flamingo;
}
