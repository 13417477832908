@import "src/styles/variable";

.main-app-dropdown-font {
  font-family: Inter, sans-serif;
  font-size: 16px;
}

.main-app-dropdown-icon {
  margin-left: 12px;
  margin-right: 30px;
  font-size: 16px;
  cursor: pointer;
}

@media (min-width: $large-devices) {
  .main-app-dropdown-menu {
    display: none;
  }
}
