@import "src/styles/variable";
@import "../../font";

.main-app-header {
  background: $white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 5px 8px rgb(0 0 0 / 10%);
  font-style: normal;
  font-weight: 630;
  font-size: 18px;
  color: $black;
  font-variation-settings: "slnt" 0;
  padding-left: 53px;
  padding-right: 40px;
  height: 8%;
  min-height: 80px;
  position: sticky;
  top: 0;
  z-index: 999;
}

.main-app-header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.main-app-header-app-name-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.main-app-header-icon {
  left: 12.5%;
  right: 12.5%;
  top: 12.5%;
  bottom: 12.5%;
  font-size: 28px;
  width: 18px;
  height: 18px;
  color: $amethyst-purple;
  margin-right: 9.28px;
  line-height: 18px;
}

.main-app-header-app-name {
  width: 39px;
  height: 35px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-size: 24px;
  color: $amethyst-purple;
  line-height: 35px;
  margin-left: 8px;
}

.main-app-header-user-name {
  height: 29px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: $light-gray;
  white-space: nowrap;
  line-height: 29px;
  margin: 0;
}

.main-app-header-side-menu-sub {
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 35px;
  font-size: 18px;
  margin-left: 50px;
  cursor: pointer;
  color: $light-gray;

  &:hover {
    color: $wave-flamingo;
  }
}

.main-app-header-side-menu-sub-active {
  color: $wave-flamingo;
  font-weight: bold;
}

.main-app-dropdown-wrapper {
  display: flex;
  align-items: center;
}

@media (max-width: $large-devices) {
  .main-app-menu {
    display: none;
  }
}
